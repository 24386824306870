import React from 'react';
import "./_loader.scss";
const Loader = () => {
    return(
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    )
}
export default Loader;