export const rateAndTrand = [
  {
    title: "Locality Average Price",
    image: "Screenshot(134)1.png",
    dropdown: ["flats", "flats", "flats", "flats"],
    location: [
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
    ],
  },
  {
    title: "Locality Average Price",
    image: "Screenshot(134)1.png",
    dropdown: ["flats", "flats", "flats", "flats"],
    location: [
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
      {
        title: "Sarvapriya  Vihar",
        number: 61,
      },
    ],
  },
];
