import React from "react";
import ApplyOnilne from "../components/Service/HomeLone/ApplyOnilne";

const HomeLoneOnline = () => {
  return (
    <div className="space-y-10 py-10">
      <div className="text-center font-semibold text-xl">
        Apply for Home Loan
      </div>
      <ApplyOnilne />
    </div>
  );
};

export default HomeLoneOnline;
