export const navigation = [
  {
    link: "/",
    title: "Home",
    subTitle:[]
  },
  {
    link: "/",
    title: "Property Service",
    subTitle: [
      // {
      //   title: "Buy",
      //   link: "/productSearch",
      // },
      {
        title: "Sale",
        link: "/productSearch",
      },
      {
        title: "Rent",
        link: "/productSearch",
      },
      {
        title: "Invest",
        link: "/productSearch",
      },
      // {
      //   title: "Projects",
      //   link: "/productSearch/",
      // },
    ],
  },
  {
    link: "/propertyDemand",
    title: "Property Demand",
    subTitle: [],
  },
  {
    link: "/requirementForm",
    title: "Requirement form",
    subTitle: [],
  },
  {
    link: "/agents",
    title: "Find Agent",
    subTitle: [],
  },
  // {
  //   link: "/",
  //   title: "Other service",
  //   subTitle: [
  //     {
  //       title: "Home Loan",
  //       link: "/homeLone",
  //     },
  //     {
  //       title: "Pay Rent Online",
  //       link: "/payRentOnline",
  //     },
  //     {
  //       title: "legal Help",
  //       link: "/legalHelp",
  //     },

  //     // {
  //     //   title: "Buy/sell services",
  //     //   link: "#",
  //     // },

  //     // {
  //     //   title: "property lawyers",
  //     //   link: "/",
  //     // },
  //     // {
  //     //   title: "Home inspection",
  //     //   link: "/",
  //     // },
  //     // {
  //     //   title: "design and Decor",
  //     //   link: "/",
  //     // },
  //   ],
  // },
];
