import React from "react";
import fallBackSrc from "../../assets/images/noUser.png";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

const AgentListItem = ({ data }) => {
  const {
    id,
    first_name,
    last_name,
    photo,
    address_1,
    address_2,
    email,
    website,
  } = data;

  let displayName = "";

  if (!first_name || !last_name) {
    displayName = "Shetal Property Agent";
  } else {
    displayName = `${first_name} ${last_name}`;
  }

  return (
    <div className="flex items-center px-4 py-4 sm:px-6">
      <div className="min-w-0 flex-1 flex items-center">
        <div className="flex-shrink-0 h-16 w-16 rounded-full overflow-hidden bg-gray-200">
          <img
            className="h-full w-full object-cover object-center rounded-full"
            alt=""
            src={photo || fallBackSrc}
          />
        </div>
        <div className="min-w-0 flex-1 px-4 md:grid lg:grid-cols-3 items-center md:gap-4">
          <div className="space-y-1">
            <Link
              to={`/agent/${id}`}
              className="text-sm font-medium text-red-600 truncate"
            >
              {displayName}
            </Link>
          </div>
          {(address_1 || address_2) && (
            <div className="lg:block">
              <p className="text-sm text-gray-500 truncate">
                Address: <br />
                <strong>{address_1}</strong> <br />
                <strong>{address_2}</strong>
              </p>
            </div>
          )}
          <div className="hidden md:block">
            <div>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {email} <br /> {website}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Link to={`/agent/${id}`}>
          <ChevronRightIcon
            className="h-7 w-7 text-red-700"
            aria-hidden="true"
          />
        </Link>
      </div>
    </div>
  );
};

export default AgentListItem;
